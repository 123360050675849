<template>
  <div v-loading="loading">
    <div class="flex-col">
      <div class="editor-item">
        <template v-if="rulesArr && rulesArr.length">
          <form-create
            :rule="rulesArr"
            v-model="fApi"
            :option="options"
            :value.sync="myValue"
          />
        </template>
      </div>
      <!--      <div class="jump-btn" v-if="rulesArr && rulesArr.pageJump && rulesArr.pageJump.isShow">-->
      <!--        <el-button size="mini" plain @click="handleJumpPage">{{rulesArr.pageJump.title}}-->
      <!--        </el-button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

/* form-create 组件挂载 */
// import StRadioButtonGroup from "@/modules/mail/components/Company/FormComponents/StRadioButtonGroup";
// import StNumberSlider from "../FormComponents/StNumberSlider";
// import StDragOptionList from "@/modules/mail/components/Company/FormComponents/StDragOptionList";
import formCreate from "@form-create/element-ui";

import json from "@/modules/big-data/data/form-create";
import { getHomeOverviewOptions } from "@/modules/big-data/api/design";
import ChartPicker from "@/modules/big-data/components/form/ChartPicker";
import MemberMapDataShow from "./form/MemberMapDataShow";

// formCreate.component(StNumberSlider)
// formCreate.component(StRadioButtonGroup)
// formCreate.component(StDragOptionList)

formCreate.component(MemberMapDataShow);
formCreate.component(ChartPicker);

export default {
  name: "StFormEditor",
  components: {
    formCreate: formCreate.$form(),
  },
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
    templateKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      fApi: {},
      options: {
        // 整体表单样式规则
        form: {
          size: "small",
          labelWidth: "96px",
          labelPosition: "top",
        },
        submitBtn: {
          show: false,
        },
        mounted: (fApi) => {
          fApi.rule.forEach((item, i) => {});
        },
        onSubmit: (formData) => {
          console.log(formData);
        },
      },
      rulesArr: [],
    };
  },
  computed: {
    // ...mapState('companyFormEditor', ['editing', 'configs']),
    // rulesArr() {
    //   return this.getRules()
    // },
    myValue: {
      get() {
        let form = json(this.value)[this.templateKey]?.form;

        let mergeResult = form ? this.$mergeModel(form, this.value) : this.value;
        // console.log(this.value,mergeResult,"form");

        return mergeResult;
      },
      set(val) {
        this.$emit("updateValue", val);
      },
    },
  },
  created() {
    // console.log('st-form-editor created. ')
    this.getRules();
  },
  methods: {
    getRules() {
      this.loading = true;
      const tasks = [];
      if (this.templateKey === "home_overview") {
        tasks.push(
          new Promise((resolve) => {
            const data_show = this.$store.state.bigDataDesign.data_show;
            if (data_show[this.templateKey]?.length) {
              resolve();
            } else {
              // getHomeOverviewOptions().then(res => {
              //   data_show[this.templateKey] = res.data
              //   this.$store.commit('bigDataDesign/setStateAttr', {
              //     key: 'data_show',
              //     val: data_show
              //   })
              //   console.log('data ready')
              resolve();
              // }).catch(() => {
              //   resolve()
              // })
            }
          })
        );
      }
      Promise.all(tasks)
        .then(async (res) => {
          this.loading = false;

          const formCreator = json(() => {
            return this.fApi;
          })[this.templateKey]
          this.rulesArr = typeof (formCreator?.rules) !== 'function' ?
            (formCreator?.rules || []) : (await formCreator?.rules() || []);
          // console.log(this.rulesArr)
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 表单底部页面跳转
    handleJumpPage() {
      let routerData = this.$router.resolve({
        name: this.rulesArr.pageJump.routeName,
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.jump-btn {
  /*position: sticky;*/
  bottom: 0;
  z-index: 2;
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .el-button {
    width: 100%;
  }
}

::v-deep .group-title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #1a1a1a;
  margin-bottom: 20px;
}

::v-deep .el-form-item {
  .el-form {
    .el-col {
      .el-form-item {
        margin-bottom: 0;
      }
    }
    /*.el-col:not([style*="display"]):nth-last-col() {*/
    /*  .el-form-item {*/
    /*    margin-bottom: 0;*/
    /*  }*/
    /*}*/
  }
}
</style>
