<template>
  <el-select v-model="myValue.value" value-key="key" style="width: 100%;">
    <el-option v-for="op in myValue.options" :key="op.key"
               :label="op.name" :value="op">
      <span>{{ op.name }}</span>
<!--      <img class="option-icon" -->
<!--           :src="form.figure === op.label?op.actived_icon:op.default_icon" />-->
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "ChartPicker",
  model: {
    prop: "value",
    event: "updateValue",
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

    }
  },
  computed: {
    myValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("updateValue", val);
      },
    },
  },
}
</script>

<style scoped>

</style>
