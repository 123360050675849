<template>
  <div
    class="container fullscreen"
    :style="cssVariables"
    @click="globalClick"
    v-loading="loading"
  >
    <div class="header flex">
      <div class="header-left flex">
        <!-- 退出编辑按钮：点击时返回到页面装修页 -->
        <el-button
          class="menu-bar-btn"
          @click="closeWindow"
          :disabled="loading"
        >
          <span class="menu-bar-btn-content">
            <i class="el-icon-switch-button" />
            <span class="menu-bar-btn-text">退出编辑</span>
          </span>
        </el-button>
      </div>
      <div class="header-middle flex-1">
        <!--        激活下标{{dataIndex}}-->
      </div>
      <div class="header-right flex">
        <el-button class="menu-bar-btn" @click="toggleFullscreen">
          <span class="menu-bar-btn-content">
            <i class="el-icon-full-screen" />
            <span class="menu-bar-btn-text">全屏</span>
          </span>
        </el-button>
        <el-button class="menu-bar-btn" @click="save" :disabled="loading">
          <span class="menu-bar-btn-content">
            <i class="el-icon-receiving" />
            <span class="menu-bar-btn-text">保存</span>
          </span>
        </el-button>
      </div>
    </div>
    <div class="workspace">
      <div class="flex-1" id="content-box" style="position: relative">
        <!-- 选择显示数据表单弹窗 -->
        <div class="right-float" @click.stop>
          <transition name="bounce">
            <el-card v-if="showFormCard && dataIndex >= 0">
              <div slot="header" class="flex-between clearfix">
                <span>{{ dataList[dataIndex].name }}设置</span>
                <span
                  class="cursor-pointer"
                  @click="showFormCard = false"
                  style="padding: 4px"
                  ><i class="el-icon-close"
                /></span>
              </div>
              <st-form-editor
                v-model="dataList[dataIndex].config"
                :template-key="dataList[dataIndex].index"
              />
            </el-card>
          </transition>
        </div>
        <el-scrollbar>
          <!-- 这样有利于 grid-layout 初始化，避免无法预料的 bug -->
          <div v-if="!loading" class="padding">
            <div
              class="stage-head flex-between"
              :style="{
                transform: `scale(${(colWidth * 4 + 9 * 5) / 1920})`,
              }"
            >
              <div class="left flex-align-center">
                <!--                <img class="logo" />-->
                <single-media-wall
                  v-model="logo"
                  :width="150"
                  :height="37"
                  :use-default-avatar="!logo"
                  :show-delete="false"
                  transparent
                  :avatar-url="
                    require('@/modules/big-data/assets/社团云 - 白@0.75.png')
                  "
                  directly
                  @handleMediaData="saveLogo"
                />
                <img class="title" src="../assets/h1.png" />
              </div>
              <div class="middle flex">
                <div
                  class="page-btn flex-col"
                  :class="[currentTabPage === 'home' ? 'active' : '']"
                  @click="switchTab('home')"
                >
                  首页
                </div>
                <div
                  class="page-btn flex-col"
                  :class="[currentTabPage === 'memberStats' ? 'active' : '']"
                  @click="switchTab('memberStats')"
                >
                  会员分析
                </div>
                <div
                  class="page-btn flex-col"
                  :class="[currentTabPage === 'orderStats' ? 'active' : '']"
                  @click="switchTab('orderStats')"
                >
                   财务分析
                </div>
              </div>
              <div class="right flex">
                <!--                <div class="btn flex-col flex-center" @click.stop="dataList = []">-->
                <!--                  <img class="icon" src="../assets/download.png" />-->
                <!--                  &lt;!&ndash;          <i class="el-icon-printer" />&ndash;&gt;-->
                <!--                  <span class="text">一键清空</span>-->
                <!--                </div>-->
                <div class="btn flex-col flex-center">
                  <img class="icon" src="../assets/download.png" />
                  <!--          <i class="el-icon-printer" />-->
                  <span class="text">导出数据</span>
                </div>
                <div class="btn flex-col flex-center">
                  <img class="icon" src="../assets/decorate.png" />
                  <!--          <i class="el-icon-brush" />-->
                  <span class="text">页面装修</span>
                </div>
                <div
                  class="btn flex-col flex-center cursor-pointer"
                  @click="toggleFullscreen"
                >
                  <i class="el-icon el-icon-full-screen" />
                  <span class="text">全屏</span>
                </div>
                <div class="btn flex-col flex-center">
                  <!--          <img class="icon" />-->
                  <i class="el-icon el-icon-close" />
                  <span class="text">关闭</span>
                </div>
              </div>
            </div>
            <div class="stage-behind" style="color: white">
              <!--              {{dataList.map(el => ({name: el.name, i: el.i}))}}-->
              <!--              {{layout.map(el => ({name: el.name, i: el.i}))}}-->
              <div id="content">
                <!--
                vertical-compact 上方元素被移走时，该元素自动上移补位
                :is-bounded="true"
                :auto-size="true"
                id="content"
                -->
                <grid-layout
                  ref="gridlayout"
                  class="stage grid"
                  :layout.sync="dataList"
                  :colNum="colNum"
                  :row-height="(colWidth * 306) / 460"
                  :margin="[9, 9]"
                  :is-draggable="true"
                  :is-resizable="true"
                  :vertical-compact="true"
                  :style="{
                    minHeight: `calc(${
                      ((colWidth * 306) / 460) * 3
                    }px + 9px * 4)`,
                  }"
                  @layout-updated="layoutUpdatedEvent"
                >
                  <div
                    class="before"
                    :style="{
                      backgroundSize: `calc(calc(100% - 9px) / 4) ${
                        (colWidth * 306) / 460 + 9
                      }px`,
                    }"
                  />
                  <grid-item
                    :key="item.i"
                    v-for="(item, dI) in dataList"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    @moved="movedEvent"
                    @resized="onGridItemResize"
                  >
                    <!-- TODO: test only -->
                    <!--                    <span class="text" style="color: red; font-weight: bold;">{{ item.i }}&#45;&#45;{{item.name}}</span>-->
                    <div
                      class="stage-item"
                      :class="{ active: false && dataIndex === dI }"
                      @click="setActive(item, dI)"
                    >
                      <div class="drag-item" style="color: white">
                        <template-card
                          :ref="`tCard_${dI}_0`"
                          :data="item"
                          grid
                          :index="dI + 999"
                          @delete="handleDelete(dI)"
                        >
                        </template-card>
                      </div>
                    </div>
                  </grid-item>
                </grid-layout>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="workspace-right">
        <!-- 侧边菜单 -->
        <div class="resize-bar" />
        <div class="resize-line" />
        <div class="resize-save flex-col" v-loading="rightLoading">
          <div class="tabs">
            <div
              class="tab"
              :class="{
                active: rightTabIndex === 0,
              }"
              @click="switchPanel(0)"
            >
              <i class="icon el-icon-pie-chart" />
              <span>模板</span>
            </div>
            <div
              class="tab"
              :class="{
                active: rightTabIndex === 1,
              }"
              @click="switchPanel(1)"
            >
              <i class="icon el-icon-setting" />
              <span>设置</span>
            </div>
          </div>
          <div class="toggle-content flex-1">
            <div
              class="template-list flex-col"
              :style="{
                zIndex: rightTabIndex === 0 ? 1 : -1,
              }"
            >
              <div v-if="rightTabIndex === 0" class="search">
                <el-input
                  v-model="keyword"
                  prefix-icon="el-icon-search"
                  placeholder="搜索数据模版"
                  clearable
                />
                <el-divider />
              </div>
              <div class="list flex-1">
                <el-scrollbar>
                  <div
                    @drag="drag"
                    @dragend="dragend"
                    class="card-box"
                    draggable="true"
                    :data-template="JSON.stringify(t)"
                    unselectable="on"
                    :id="t.index"
                    v-for="t in templateList.filter(
                      (el) =>
                        !keyword || (keyword && el.name.indexOf(keyword) !== -1)
                    )"
                  >
                    <template-card
                      ref="myCard"
                      :key="t.index"
                      :data="t"
                      :index="
                        templateList.findIndex((el) => el.name === t.name)
                      "
                    />
                  </div>
                  <p class="footer-tip">-- 到底了 --</p>
                  <div style="height: 20px" />
                </el-scrollbar>
              </div>
            </div>
            <div
              v-if="rightTabIndex === 1"
              class="setting flex-1"
              :style="{
                zIndex: rightTabIndex === 1 ? 1 : -1,
              }"
            >
              <el-scrollbar>
                <div class="list">
                  <el-empty
                    v-if="dataList.length === 0"
                    description=""
                    style="margin-top: 15vh"
                  />
                  <el-collapse v-else v-model="activeName" :accordion="false">
                    <el-collapse-item
                      :title="item.name + '设置'"
                      :id="`setting_${item.i}`"
                      v-for="(item) in dataList"
                      :disabled="!item.config"
                      :name="item.i"
                      :key="item.i"
                    >
                      <template #title>
                        <span class="setting-title">{{ item.name }}设置</span>
                      </template>
                      <div>
                        <!--                      TODO: 仅测试 {{item.config}}-->
                        <template v-if="item.config">
                          <st-form-editor
                            v-model="item.config"
                            :template-key="item.index"
                          />
                        </template>
                        <el-empty v-else description="暂无设置" />
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { randomString } from "@/base/utils/tool";

import VueGridLayout from "vue-grid-layout";
import { addListener, removeListener } from "resize-detector";

import TemplateCard from "../components/TemplateCard";

import { getBigDataTemplates, getMemberStatsTemplates,getOrderStatsTemplates } from "../api/design";
// 获取首页配置，保存首页配置
import {
  getHome,
  saveHomeBigData,
  getMemberStats,
  saveMemberStats,
  getOrderStats,
  saveOrderStats
} from "@/modules/big-data/api/design";

import StFormEditor from "../components/StFormEditor";

import {
  getHomeMemberFormDataOptions,
  getHomeOverviewOptions,
  getMemberInteractShowOptions,
} from "@/modules/big-data/api/form";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import {
  getOverviewPageConfig,
  saveOverviewPageConfig,
} from "@/modules/big-data/api/base";

let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 1, h: 1, i: null };

export default {
  components: {
    SingleMediaWall,
    StFormEditor,
    TemplateCard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      // 当前tab页面
      currentTabPage: "home",
      cssVariables:
        "--aside-width: " +
        "270px; " +
        "--bg-color: rgba(3, 23, 26, 1); " +
        "--hover-color: rgba(75, 210, 237, 1);",
      keyword: "",
      //装修组件库列表
      templateList: [],
      // 当前数据下标
      dataIndex: -1,
      //  数据列表
      dataList: [],

      colWidth: 0,
      // 0: 模板 1： 设置
      rightTabIndex: 0,
      activeName: [],
      colNum: 4,
      loading: true,
      rightLoading: true,
      layout: [
        // { "x": 1, "y": 0, "w": 2, "h": 2, "i": "0", "name": "会员地图" },
        // { "x": 3, "y": 0, "w": 1, "h": 1, "i": "1", "name": "会费收入" },
        // { "x": 0, "y": 0, "w": 1, "h": 1, "i": "2", "name": "数据总览" },
        // { "x": 0, "y": 1, "w": 1, "h": 1, "i": "3", "name": "企业行业类别" },
        // { "x": 0, "y": 2, "w": 4, "h": 1, "i": "4", "name": "会费收入" }
        // {"x": 0, "y": 0, "w": 2, "h": 3, "i": "0"},
        // {"x": 2, "y": 0, "w": 1, "h": 3, "i": "1"},
        // {"x": 3, "y": 0, "w": 1, "h": 2, "i": "2"},
        // // {"x": 6, "y": 0, "w": 2, "h": 3, "i": "3"},
        // // {"x": 8, "y": 0, "w": 2, "h": 3, "i": "4"},
        // // {"x": 10, "y": 0, "w": 2, "h": 3, "i": "5"},
        // // {"x": 0, "y": 5, "w": 2, "h": 5, "i": "6"},
        // {"x": 0, "y": 3, "w": 4, "h": 1, "i": "7"},
        // // {"x": 4, "y": 5, "w": 2, "h": 5, "i": "8"},
        // // {"x": 5, "y": 10, "w": 4, "h": 3, "i": "9"},
      ],
      showFormCard: false,
      // dragging: null // 调试用的

      logo: "",
    };
  },
  watch: {
    "dataList.length"(val, oldVal) {},
  },
  created() {
    // 初始化
    this.init(this.currentTabPage);
    this.initTemplateList(this.currentTabPage);
    // 获取大数据配置 {logo:xxx}
    getOverviewPageConfig()
      .then((res) => {
        this.logo = res.data.logo;
      })
      .catch(() => {});
  },
  mounted() {
    this.container = document.querySelector("#content-box");
    this.queryColWidth();
    this.addResize();

    /* 一套监听下来，被拖拽的元素在 drop 后松开鼠标时，就不会弹回原处了 */
    // document.addEventListener("dragstart", function(event) {
    //   event.dataTransfer.setData("Text", event.target.id);
    // });

    document.addEventListener("dragover", function (event) {
      mouseXY.x = event.clientX;
      mouseXY.y = event.clientY;
      event.preventDefault();
    });

    // document.addEventListener("drop", function(event) {
    //   event.preventDefault();
    //   /** 以下是想实现元素放下时不会原路弹回，但好像不写也行，只要监听事件都足够了 */
    //   // var data = event.dataTransfer.getData("Text");
    //   // event.target.appendChild(document.getElementById(data));
    //   //
    //   // setTimeout(() => {
    //   //   event.target.removeChild(document.getElementById(data));
    //   // }, 100)
    // });
  },
  beforeDestroy() {
    this.removeResize();

    document.removeEventListener(
      "dragover",
      function (e) {
        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  },
  methods: {
    // 保存logo
    saveLogo() {
      saveOverviewPageConfig({
        config: JSON.stringify({
          logo: this.logo,
        }),
      })
        .then((res) => {
          this.$message.success(res.msg);
        })
        .catch(() => {});
    },
    // 点击非内容区域取消选中
    globalClick() {
      this.dataIndex = -1;
      this.showFormCard = false;
      this.dataList.forEach((d, dI) => {
        const refArr = this.$refs[`tCard_${dI}_0`];
        if (refArr?.length) {
          refArr[0].showTime = false;
        } else if (refArr?.showTime !== undefined) {
          refArr.showTime = false;
        }
      });
    },
    // 设置选中的卡片
    setActive(d, dI) {
      this.dataIndex = dI;
      if (d.config) {
        this.rightTabIndex = 1;
      } else {
        this.$message.info("暂无设置");
      }

      this.$nextTick(() => {
        if (this.rightTabIndex === 1) {
          if (!this.activeName.find((el) => el === d.i) && d.config) {
            this.activeName.push(d.i);
          }

          if (this.activeName.includes(d.i)) {
            const element = document.getElementById(`setting_${d.i}`);
            setTimeout(() => {
              element.scrollIntoView({
                block: "center",
                behavior: "smooth",
              });
              element.classList.add("focus");
              setTimeout(() => {
                element.classList.remove("focus");
              }, 1000);
            }, 500);
          }
        }
      });
    },
    // 删除对应卡片
    handleDelete: function (index) {
      this.dataList.splice(index, 1);
      // 更新 i 值，因为删除后拖新组件导致 i 会重复，故作此处理
      // !!! 不如用 randomString 作为 i 更好咯
      // this.dataList.forEach((d, di) => {
      //   if (d.i !== di) {
      //     this.dataList[di].i = di.toString()
      //   }
      // })
    },
    drag: function (e) {
      let parentRect = document
        .getElementById("content")
        .getBoundingClientRect();
      let mouseInGrid = false;
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (
        mouseInGrid === true &&
        this.dataList.findIndex((item) => item.i === "drop") === -1
      ) {
        this.dataList.push({
          x: (this.dataList.length * 2) % (this.colNum || 12),
          y: this.dataList.length + (this.colNum || 12), // puts it at the bottom
          w: 1,
          h: 1,
          i: "drop",
        });
      }
      let index = this.dataList.findIndex((item) => item.i === "drop");
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[
            this.dataList.length
          ].$refs.item.style.display = "none";
        } catch (err) {
          console.log(err);
        }
        let el = this.$refs.gridlayout.$children[index];
        el.innerW = 1; // 这个 innerW 容易被上一个拖进来的组件的 resize 后的 w 影响，故重新设置了一下
        el.dragging = {
          top: mouseXY.y - parentRect.top,
          left: mouseXY.x - parentRect.left,
        };
        /* 这里使用 grid-item 内置的坐标计算方式，可以查看源码了解 */
        let new_pos = el.calcXY(
          mouseXY.y - parentRect.top,
          mouseXY.x - parentRect.left
        );
        // this.dragging = {
        //   colWidth: el.calcColWidth(), // grid-item 宽度
        //   margin: el.margin, // grid-gap
        //   cols: el.cols, // 列数
        //   innerW: el.innerW, // grid-item w 属性
        //   mouseXY,
        //   new_pos,
        // }
        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent(
            "dragstart",
            "drop",
            new_pos.x,
            new_pos.y,
            1,
            1
          );
          // DragPos.i = String(index);
          DragPos.i = randomString(6);
          DragPos.x = this.dataList[index].x;
          DragPos.y = this.dataList[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent(
            "dragend",
            "drop",
            new_pos.x,
            new_pos.y,
            1,
            1
          );
          this.dataList = this.dataList.filter((obj) => obj.i !== "drop");
        }
      }
    },
    dragend: function (e) {
      let parentRect = document
        .getElementById("content")
        .getBoundingClientRect();
      // console.log('dragend', parentRect)
      let mouseInGrid = false;
      // 判断是否拖动到格子容器里面
      if (
        mouseXY.x > parentRect.left &&
        mouseXY.x < parentRect.right &&
        mouseXY.y > parentRect.top &&
        mouseXY.y < parentRect.bottom
      ) {
        mouseInGrid = true;
      }
      if (mouseInGrid === true) {
        // alert(`Dropped element props:\n${JSON.stringify(DragPos, ['x', 'y', 'w', 'h'], 2)}`);
        this.$refs.gridlayout.dragEvent(
          "dragend",
          "drop",
          DragPos.x,
          DragPos.y,
          1,
          1
        );
        this.dataList = this.dataList.filter((obj) => obj.i !== "drop");

        // UNCOMMENT below if you want to add a grid-item
        // TODO: 判断是否允许放置
        const rectMaxHeight = ((this.colWidth * 181) / 269) * 3 + 9 * 4;
        if (parentRect.height > rectMaxHeight) {
          // TODO: 不能放置
          this.$message.info("不可以放在这里哦");
          return;
        }
        {
          const templateObj = JSON.parse(e.target.dataset.template || "{}");
          this.dataList.push({
            x: DragPos.x,
            y: DragPos.y,
            w: 1,
            h: 1,
            i: DragPos.i,
            ...templateObj,
          });
          console.log(templateObj, this.dataList);
          // debugger
          this.$refs.gridlayout.dragEvent(
            "dragend",
            DragPos.i,
            DragPos.x,
            DragPos.y,
            1,
            1
          );
          try {
            this.$refs.gridlayout.$children[
              this.dataList.length
            ].$refs.item.style.display = "block";
          } catch (err) {
            console.log(err);
          }
          // 聚焦于新拖的组件
          this.dataIndex = this.dataList.length - 1;
          // 请求一下这个组件的页面装修配置项
          this.getDataShowOptions(templateObj.index, () => {
            console.log(templateObj);
            // 有 config 就弹出选择配置弹窗
            if (templateObj.config) {
              this.showFormCard = true;
            } else {
              this.$message.info("无需配置");
            }
          });
        }
      }
    },
    save() {
      console.log("*******************SAVE******************");
      console.table(
        this.dataList.map((d) => ({
          x: d.x,
          y: d.y,
          w: d.w,
          h: d.h,
          index: d.index,
          i: d.i,
          name: d.name,
        }))
      );
      console.log("*****************************************");
      this.loading = true;
      // 不同的tab 调用不同的保存配置接口
      let saveApi = "";
      switch (this.currentTabPage) {
        // 首页
        case "home":
          saveApi = saveHomeBigData;
          break;
        // 会员分析
        case "memberStats":
          saveApi = saveMemberStats;

          break;
          case "orderStats":
          saveApi =  saveOrderStats;

          break;
          
        default:
          this.$message.error("错误的tab路径");
          throw new Error("错误的tab路径");
          return;
      }
      console.log(this.update_time);
      saveApi({
        config: JSON.stringify({
          update_time:
            this.update_time == -1
              ? parseInt((new Date().getTime() / 1000).toFixed(0))
              : parseInt(this.update_time),
          component_list: this.dataList,
        }),
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.init(this.currentTabPage);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 右侧切换tab
    switchPanel(val) {
      this.rightTabIndex = val;
      this.$set(this, "rightTabIndex", val);
    },
    /** 查询最小单位格子宽度 */
    queryColWidth() {
      const layer = document.getElementById("content-box");
      if (!layer) throw new Error();
      // this.layerScale = (layer.offsetWidth - 21 - 25) / 1920
      let layerWidth = layer.offsetWidth - 21 - 25;
      this.layerWidth = layerWidth;
      this.colWidth = Math.round((layerWidth - 5 * 9) / 4);
    },
    /**
     * 监听格子大小变化
     * @param i the item id/index
     * @param newH new height in grid rows
     * @param newW new width in grid columns
     * @param newHPx new height in pixels
     * @param newWPx new width in pixels
     */
    onGridItemResize(i, newH, newW, newHPx, newWPx) {
      // console.log('grid item resize: ', i)
      if (i !== undefined) {
        const target = this.dataList.find((el) => el.i === i);
        let parentRect = document
          .getElementById("content")
          .getBoundingClientRect();
        // console.log(parentRect)
        const rectMaxHeight = ((this.colWidth * 181) / 269) * 3 + 9 * 4;
        if (parentRect.height > rectMaxHeight) {
          // console.log('超过画布大小')
          this.$message.info("不可以放在这里哦");
          //   const gridlayout = this.$refs.gridlayout
          // TODO: 不能放大，还原大小，这里还不能记录到大小变化前的值
          const targetIndex = this.dataList.findIndex((el) => el.i === i);
          const originalList = this.originalLayoutJSON
            ? JSON.parse(this.originalLayoutJSON || "[]") || []
            : this.dataList;
          const originalTarget = originalList.find((el) => el.i === i);

          // const {config: cO, name: nO, ...otherO} = originalTarget
          // const {config: cT, name: nT, ...otherT} = target
          // console.table([
          //   otherT, otherO
          // ])

          setTimeout(() => {
            this.dataList.splice(targetIndex, 1);
            this.dataList.splice(targetIndex, 0, originalTarget);
            this.resizeCard();
          }, 500);
          return;
        }
      }
      this.resizeCard();
    },
    /** 监听右侧的模板列表宽度变化 */
    onTemplateItemResize() {
      if (this.$refs.myCard?.length) {
        this.$refs.myCard.forEach((r) => {
          // console.log(r)
          r.resize();
        });
      } else if (this.$refs.myCard) {
        this.$refs.myCard.resize();
      }
    },
    // 添加resize事件
    addResize() {
      addListener(this.container, this.onBarResize);
    },
    // 监听容器大小变化，使 template-card 组件自适应
    onBarResize(e) {
      // console.log('bar resize', e)
      this.queryColWidth();
      this.onTemplateItemResize();
      this.onGridItemResize();
    },
    // 移除监听resize
    removeResize() {
      removeListener(this.container, this.onBarResize);
    },
    // 重置卡片
    resizeCard() {
      this.dataList.forEach((d, dI) => {
        const refArr = this.$refs[`tCard_${dI}_0`];
        if (refArr?.length) {
          refArr[0].resize();
        } else {
          refArr?.resize();
        }
      });
    },
    movedEvent: function (i, newX, newY) {
      console.log("---------------------------------");
      console.log("MOVE i=" + i + ", X=" + newX + ", Y=" + newY);
      // this.dataIndex = 4 * newY + newX

      const target = this.dataList.find((el) => el.i === i);
      let parentRect = document
        .getElementById("content")
        .getBoundingClientRect();
      const rectMaxHeight = ((this.colWidth * 181) / 269) * 3 + 9 * 4;
      if (parentRect.height > rectMaxHeight) {
        console.log("超过画布大小");
        this.$message.info("不可以放在这里哦");
        const targetIndex = this.dataList.findIndex((el) => el.i === i);
        const originalList = this.originalLayoutJSON
          ? JSON.parse(this.originalLayoutJSON || "[]") || []
          : this.dataList;
        const originalTarget = originalList.find((el) => el.i === i);

        setTimeout(() => {
          this.dataList.splice(targetIndex, 1);
          this.dataList.splice(targetIndex, 0, originalTarget);
          this.resizeCard();
        }, 500);
      }
    },
    // 获取数据展示配置
    getDataShowOptions(index = "", callback = () => {}) {
      let api, params;
      switch (index) {
        case "home_overview": {
          api = getHomeOverviewOptions;
          params = [{}];
          break;
        }
        case "member_map":
        case "member_photo": {
          api = getHomeMemberFormDataOptions;
          params = [
            {
              form_type: "personal_user",
              component: index,
            },
            {
              form_type: "unit_user",
              component: index,
            },
          ];
          break;
        }
        // 活跃会员
        case "member_interact": {
          api = getMemberInteractShowOptions;
          params = [{}];
          break;
        }
        default:
          return Promise.resolve();
      }
      console.log(index, "init options", params);

      return Promise.all(
        (() => {
          return params.map((p) => {
            return new Promise((resolve) => {
              const data_show = this.$store.state.bigDataDesign.data_show;
              let data_show_key = index;
              if (p.form_type !== undefined)
                data_show_key = `${index}_${p.form_type}`;
              if (
                JSON.stringify(data_show[data_show_key]) !== "{}" &&
                JSON.stringify(data_show[data_show_key]) !== "[]"
              ) {
                resolve();
              } else {
                api(p)
                  .then((res) => {
                    data_show[data_show_key] = res.data;
                    console.log(data_show);
                    this.$store.commit("bigDataDesign/setStateAttr", {
                      key: "data_show",
                      val: data_show,
                    });
                    resolve();
                  })
                  .catch(() => {
                    resolve();
                  });
              }
            });
          });
        })()
      )
        .then(() => {
          console.log("suc", index);
          callback();
        })
        .catch((err) => {
          console.log(err, index);
          callback();
        });
    },
    /** 准备好十二个格子 */
    init: function (tab = "home") {
      let apiObj = {
        // 左侧数据卡片配置接口 12宫格
        dataApi: "",
        // 右侧卡片模板列表接口
        templateListApi: "",
      };
      switch (tab) {
        // 首页
        case "home":
          apiObj = {
            dataApi: getHome,
            templateListApi: getBigDataTemplates,
          };

          break;
        // 会员分析
        case "memberStats":
          apiObj = {
            dataApi: getMemberStats,
            templateListApi: getMemberStatsTemplates,
          };
          break;
          case "orderStats":
          apiObj = {
            dataApi: getOrderStats,
            templateListApi: getOrderStatsTemplates,
          }
          break;
        default:
          this.$message.error("错误的tab路径");
          throw new Error("错误的tab路径");
          return;
      }
      // 进入到正确的大数据页面路由
      if (apiObj.dataApi) {
        // 获取对应页面的数据配置
        apiObj
          .dataApi()
          .then((res) => {
            this.update_time = res.data.update_time;
            const list = res.data.component_list.map((el, elIndex) => {
              const { moved, ...obj } = el;
              return {
                ...obj,
                // i: elIndex.toString()
              };
            });
            // 有组件请求组件的配置参数
            if (list.length) {
              // TODO: 组件接口数据预拉取
              Promise.all(
                (() => {
                  const tasks = [];
                  list.forEach((li) => {
                    tasks.push(this.getDataShowOptions(li.index));
                  });
                  return tasks;
                })()
              )
                .then(() => {
                  console.log("options ready");
                  // TODO: 处理已保存的数据并回显
                  this.dataList = [];
                  this.dataList.push(...list);
                  this.loading = false;
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
            } else {
              // 恢复初始状态
              this.$store.commit("bigDataDesign/setStateAttr", {
                key: "data_show",
                val: {
                  home_overview: [],
                  member_map_unit_user: {},
                  member_map_personal_user: {},
                  member_photo_unit_user: {},
                  member_photo_personal_user: {},
                  member_interact: {},
                },
              });
              // 数据列表也清空
              this.dataList = [];

              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
    // 初始化右侧模板列表
    initTemplateList(tab = "home") {
      let apiObj = {
        // 左侧数据卡片配置接口 12宫格
        dataApi: "",
        // 右侧卡片模板列表接口
        templateListApi: "",
      };
      switch (tab) {
        // 首页
        case "home":
          apiObj = {
            dataApi: getHome,
            templateListApi: getBigDataTemplates,
          };

          break;
        // 会员分析
        case "memberStats":
          apiObj = {
            dataApi: getMemberStats,
            templateListApi: getMemberStatsTemplates,
          };
          break;
        case "orderStats":
          apiObj = {
            dataApi: getOrderStats,
            templateListApi: getOrderStatsTemplates,
          }
          break;
        default:
          this.$message.error("错误的tab路径");
          throw new Error("错误的tab路径");
          return;
      }
      // 获取对应页面装修所有装修组件库列表
      if (apiObj.templateListApi) {
        apiObj
          .templateListApi()
          .then((res) => {
            this.templateList = res.data;
            this.rightLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.rightLoading = false;
          });
      } else {
        this.templateList = [];
        this.rightLoading = false;
      }
    },
    /**
     * 退出编辑
     */
    closeWindow() {
      this.$message.info("退出编辑");
      window.opener = null;
      window.close();
    },
    /* 进入/退出全屏 */
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.fullScreen();
      } else {
        this.exitScreen();
      }
    },
    // 全屏
    fullScreen() {
      var el = document.documentElement;
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    // 退出全屏
    exitScreen() {
      var el = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
      }
    },
    layoutUpdatedEvent: function (newLayout) {
      this.originalLayoutJSON = JSON.stringify(newLayout);
      // console.log("Updated layout: ", JSON.stringify(newLayout))
    },
    // 切换不同的页面
    switchTab(tab) {
      // this.$router.replace({
      //   name: "BigDataDesign",
      //   params: {
      //     tab: "memberStats",
      //   },
      // });
      // console.log("替换");
      // /**  刷新当前界面 */
      // this.$router.go(0);
      this.loading = true;
      this.rightLoadin = true;
      this.dataIndex = -1;
      this.keyword = "";
      this.activeName = [];
      // 改变当前页面page
      this.currentTabPage = tab;
      this.init(tab);
      this.initTemplateList(tab);
    },
  },
};
</script>

<style lang="scss" scoped>
$header-h: 61px;
$row-h: calc((100vh - #{$header-h} - 40px - 63px) / 3);

.container {
  margin: calc(-#{$space} * 2);
  background-color: #eef2f8;

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;
  }
}

.header {
  width: 100%;
  height: $header-h;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.stage-behind {
  position: relative;
  width: 100%;
  /*    @include fixed-ratio-box(56.25%);*/
  /*    transform-origin: center;*/
  /*    width: 1920px;*/
  /*height: 1080px;*/
  /*transform-origin: top left;*/

  /*background-color: #06161A;*/
  background-color: #062229;
}

.stage-head {
  height: 88px;
  width: 1920px;
  background-color: #092328;
  background-image: url("~@/modules/big-data/assets/head-bg.png"),
    url("~@/modules/big-data/assets/head-logo-bg.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: white;
  transform-origin: left bottom;
  padding-left: 42px;

  .title {
    height: 55px;
    margin-left: $space;
  }

  .btn {
    padding: 0 $space;
    font-size: 14px;
    @include no-select();

    .icon {
      width: 24px;
      height: 24px;
    }

    .el-icon {
      font-size: 24px;
    }

    .text {
      font-size: 12px;
      margin-top: 4px;
    }
  }
  .page-btn {
    width: 130px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    background: url("../assets/nav-btn.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    &.active {
      background-image: url("../assets/nav-btn-active.png");
      background-size: 100% 100%;

      font-weight: 700;
      // background-color: orange;
    }
  }
  .page-btn + .page-btn {
    margin-left: 45px;
  }
}

/*.grid:before, */
.before {
  /*content: '';*/
  /*background-size: calc(calc(100% - 9px) / 4) 150px;*/

  background-image: linear-gradient(to right, #06161a 9px, transparent 9px),
    linear-gradient(to bottom, #06161a 9px, transparent 9px);
  /*background-color: black;*/

  height: calc(100%);
  width: calc(100%);
  position: absolute;
  background-repeat: repeat;
  /*margin: 9px;*/
  /*border: 9px solid #011517;*/
}

::v-deep .vue-grid-item {
  & > .vue-resizable-handle {
    /*width: 24px;*/
    /*height: 24px;*/
    background-image: unset;

    &:before,
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: white;
    }

    &:before {
      width: 20px;
      height: 5px;
    }

    &:after {
      width: 5px;
      height: 20px;
    }
  }
}

.stage {
  /*background-color: #032228;*/
  /*background-color: navajowhite;*/
  width: 100%;
  /*width: 1920px;*/
  height: 100%;
  /*display: grid;*/
  /*grid-gap: 9px;*/
  /*grid-template-columns: repeat(4, 1fr);*/
  /*!*grid-auto-flow: row dense;*!*/

  /*padding: 1px;*/

  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/

  max-height: 100%;
  /*overflow: hidden;*/

  .stage-item {
    /*background-color: rgba(4, 39, 45, 0.7);*/
    /*background-color: #062229;*/
    /*     min-height: $row-h;*/
    transition: border 0.3s;
    position: relative;
    height: 100%;
    /*border: 1px solid rgba(4, 39, 45, 0.7);*/
    /*border-width: 1px;*/
    /*border-style: solid;*/
    /*border-color: #062229;*/
    /*box-sizing: content-box;*/
    outline-width: 1px;
    outline-style: solid;
    outline-color: #062229;

    &:hover {
      .control {
        display: flex;
      }
    }

    &.active,
    &:hover {
      /*border-color: rgba(75, 210, 237, 1);*/
      outline-color: rgba(75, 210, 237, 1);
    }
  }
}

.drag-item-list,
.stage-item {
  height: 100%;

  ::v-deep .template-card {
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--bg-color);

    .preview {
      /*background-color: unset;*/
      height: 100%;

      &:before {
        padding-top: 0;
      }

      .content {
        position: relative;
      }
    }

    & > .text {
      display: none;
    }
  }
}

.workspace-right {
  /*overflow-x: visible;*/
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  float: right;
  min-width: var(--aside-width);
  width: auto;
  max-width: 90%;
}

/* 顶部菜单栏下面的一整个容器 */
.workspace {
  /*height: calc(100vh - 60px - 50px - 54px);*/
  height: 100vh;
  overflow: hidden;
  display: flex;
  padding-top: $header-h;
}

/* TEST */
$resize-bar-width: 16px;
$resize-bar-color: #eee;

.resize-save {
  position: absolute;
  top: 0;
  left: $resize-bar-width;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  background-color: white;
}

.resize-bar {
  width: var(--left-bar-width);
  min-width: var(--left-bar-width);
  max-width: 100%;
  /*height: inherit;*/
  height: calc(100vh - #{$header-h});
  resize: horizontal;
  cursor: col-resize;
  opacity: 0;
  overflow: scroll;
  transform: rotate(180deg);
}

/* 拖拽线 */
.resize-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /*border-left: 1px solid #eee;*/
  /*border-right: 2px solid #bbb;*/
  border-right: $resize-bar-width solid $resize-bar-color;
  pointer-events: none;
}

.resize-bar:hover ~ .resize-line,
.resize-bar:active ~ .resize-line {
  /*border-left: 1px dashed #3a76ff;*/
}

.resize-bar::-webkit-scrollbar {
  width: 200px;
  height: inherit;
}

/* Firefox只有下面一小块区域可以拉伸 */
@supports (-moz-user-select: none) {
  .resize-bar:hover ~ .resize-line,
  .resize-bar:active ~ .resize-line {
    border-left: 1px solid #bbb;
  }
  .resize-bar:hover ~ .resize-line:after,
  .resize-bar:active ~ .resize-line:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: -8px;
    /*background: url(./resize.svg);*/
    /*background-size: 100% 100%;*/
  }
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;

  .padding {
    padding: 40px 21px 63px 25px;
  }
}

.tabs {
  display: flex;

  .tab {
    width: 50%;
    height: 57px;
    line-height: 57px;
    background-color: #f7f7f7;
    font-size: 14px;
    text-align: center;
    color: #d5d8de;
    border-bottom: 3px solid transparent;
    @include no-select();

    &:not(.active) {
      cursor: pointer;
    }
  }

  .active {
    color: rgba(53, 118, 255, 1);
    background: rgba(255, 255, 255, 1);
    border-color: $primary;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
    font-size: 24px;
  }
}

.search {
  padding: 12px 8px 0;

  .el-divider--horizontal {
    margin: 12px 0;
  }
}

.list {
  /*flex: 1;*/
  /*overflow: auto;*/

  .card-box + .card-box {
    margin-top: 8px;
  }
}

.drag-item {
  height: 100%;
  overflow: hidden;
  @include no-select();
}

.control {
  border-radius: 50%;
  background-color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(100% - 15px);
  left: calc(100% - 15px);
  display: none;
  cursor: pointer;
  z-index: 1;

  .el-icon-d-caret {
    transform: rotate(-45deg);
    color: black;
    font-size: 20px;
  }
}

.footer-tip {
  font-size: 12px;
  color: #ccc;
  text-align: center;
  margin-top: $space;
}

.menu-bar-btn {
  background-color: unset;
  border-color: transparent;
  height: 54px;
  display: flex;
  align-items: center;

  .menu-bar-btn-content {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      margin-right: 7px;
    }

    .menu-bar-btn-text {
      line-height: 54px;
    }
  }
}

.setting .list {
  padding-left: 8px;
  padding-right: 8px;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

.card-box {
  width: 94%;
  margin: 0 auto;
}

#content-box {
  overflow: hidden;
}

.ghost {
  display: none;
}

.focus {
  outline-width: 2px;
  outline-style: solid;
  /*outline-color: var(--hover-color);*/
  outline-color: $primary;
  outline-offset: 1px;
  animation: flashing 0.6s ease infinite;
}

@keyframes flashing {
  from {
    outline-color: $primary;
  }

  to {
    outline-color: transparent;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0) translateY(100px);
    opacity: 0;
  }
  50% {
    transform: scale(1.15) translateY(20px);
    opacity: 0.6;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

.right-float {
  position: absolute;
  right: 0;
  top: 12px;
  width: 368px;
  z-index: 5;
}

.setting-title {
  font-size: 16px;
  font-weight: bold;
}

.toggle-content {
  position: relative;
  overflow: hidden;

  & > .template-list,
  & > .setting {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="scss">
#content-box #content .ghost {
  border: 1px solid var(--hover-color) !important;
  display: block;

  .preview {
    display: none;
  }
}

body {
  /*--right-pixels: 16px;*/
}

/*.el-notification.right {*/
/*  right: attr(data-right);*/
/*}*/
</style>
