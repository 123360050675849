import api from "@/base/utils/request";

export const getHomeOverviewOptions = function() {
  return api({
    url: '/admin/data_view/homePage/overviewData',
    method: 'post'
  })
}

export const getHomeMemberFormDataOptions = (data) => {
  return api({
    url: '/admin/data_view/homePage/memberApplyForm',
    method: 'post',
    data,
    notCancel: true
  })
}
// 活跃会员多选配置
export const getMemberInteractShowOptions = (data) => {
  return api({
    url: '/admin/data_view/memberStats/memberInteractShowOptions',
    method: 'post',
    data,
    notCancel: true
  })
}
