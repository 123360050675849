<template>

</template>

<script>
export default {
  name: 'MemberMapDataShow',

}
</script>

<style scoped>

</style>
